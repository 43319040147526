<template>
  <div>
    <NavigationTree
      v-if="isLoggedIn"
      :roots="menu"
      locale-section="components.navigationMain"
    />
    <NavigationTree
      v-if="!isLoggedIn"
      :roots="loginMenu"
      locale-section="components.navigationMain"
    />
  </div>
</template>

<script>
export default {
  name: "NavigationMain",
  components: {
    NavigationTree: () => import("@/components/NavigationTree")
  },
  data() {
    return {
      menu: [
        {
          to: "objects",
          children: [
            "artefacts",
            "bracteates",
            "groups",
            "crossForms",
            {
              to: "materials",
              children: ["materialTypes", "materialSubTypes"]
            },
            "periods",
            {
              to: "places",
              children: [
                "parishes",
                "hundreds",
                "provinces",
                "municipalities",
                "counties",
                "countries",
                "divisions"
              ]
            },
            "styles"
          ]
        },
        {
          to: "uris"
        },
        {
          to: "her",
          children: [
            "herDenmark",
            "herNorway",
            "herGreatBritain",
            {
              to: "herSweden",
              children: ["herSwedenParishes"]
            }
          ]
        },
        {
          to: "inscriptions",
          children: ["carvers", "runeTypes", "signa1", "signa", "languages"]
        },
        {
          to: "sources"
        }
      ],
      loginMenu: ["login"]
    };
  },
  computed: {
    isLoggedIn() {
      let vueApp = this.$root;
      return !(
        vueApp.user === undefined ||
        vueApp.user.username === undefined ||
        vueApp.user.username === ""
      );
    },
    isAdmin() {
      if (
        this.$root.user &&
        this.$root.user.roles &&
        this.$root.user.roles.length > 0
      ) {
        let roles = this.$root.user.roles;
        let match = roles.filter(role => role === "ROLE_ADMIN");
        return match !== undefined && match.length > 0;
      }

      return false;
    }
  }
};
</script>
